import React from "react"
import * as R from "ramda"
import { graphql } from "gatsby"
import { useMicroCopyEn } from "../../hooks/microCopy/en"
import GetMicroCopy from "../../shared/getMicroCopy"
import LayoutController from "../../components/layoutController"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import LiftContent from "../../components/liftContent"

const IndexPage = ({ data }) => {
  const language = "en"
  const pageContent = R.path(["etusivu"], data)
  const header = R.path(["header"], pageContent)
  const contentTextShort = R.path(["contentTextShort"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], pageContent)
  // UI texts
  const microCopyTexts = useMicroCopyEn
//   const labelInstagramTitle = GetMicroCopy(
//     microCopyTexts,
//     "yleinenInstagramOtsikko"
//   )

  return (
    <LayoutController language={language}> 
      <Seo
        seoTitle={header}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <Hero pageContent={pageContent} />
      <LiftContent pageContent={pageContent} />
    </LayoutController>
  )
}

export default IndexPage
// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query frontPageQueryEn {
    etusivu: contentfulEtusivu(
      contentTextShort: { ne: "Järjestelmätiedosto" },
      node_locale: { eq: "en" }
    ) {
      contentTextShort
      header
      mainImageContentText {
        id
        mainImageContentText
      }
      mainImageCtaButtonSlug
      mainImageCtaButtonText
      mainImage {
        id
        file {
          url
        }
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1200)
      }
      liftContent {
        ... on Node {
          ... on ContentfulNostolista {
            __typename
            id
            color
            buttonText
            buttonAction {
              ... on Node {
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  slug
                }
              }
            }
            title
            liftList {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  kategoria
                  title
                  contentTextShort
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                  title
                  contentTextShort
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  name
                  contentTextShort
                  kategoria
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on ContentfulVarinosto {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            ctaButton1Text
            ctaButton1Action {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on ContentfulArtikkelinostot {
            __typename
            title
            id
            buttonText
            buttonAction {
              ... on Node {
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  slug
                }
              }
            }
            articleLiftups {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  createdAt(formatString: "MMMM Do YYYY", locale: "fi")
                  slug
                  title
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on ContentfulSisaltoelementti {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            image {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            ctaButtonAction
          }
          ... on ContentfulVideonosto {
            __typename
            id
            title
            contentText {
              contentText
            }
            videoUrl
            thumbNail {
              id
              file {
                url
              }
              gatsbyImageData(quality: 60, layout: CONSTRAINED)
            }
            ctaButtonText
            buttonSlug
          }
          ... on ContentfulTekstilinkkilista {
            __typename
            id
            title
            contentText {
              contentText
            }
            liftList {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  title
                  slug
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                  title
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  name
                  slug
                }
              }
            }
          }
          ... on ContentfulAnimoidutNostot {
            __typename
            id
            title
            lifts {
              id
              title
              text {
                text
              }
              buttonText
              buttonLink
              buttonAction {
                ... on Node {
                  ... on ContentfulKategoriasivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulArtikkelisivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulKohdesivu {
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
